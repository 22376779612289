let createBlogBox = function(content, type = 'default') {
    let box = document.createElement('a');
    box.classList.add('box');
    if (type === 'large') {
        box.classList.add('box--large');
    }
    box.setAttribute('href', content.link);

    let category = document.createElement('div');
    category.classList.add('box__category');
    category.innerHTML = content.nt_category;

    let image_container = document.createElement('div');
    image_container.classList.add('box__image-container');

    let image = document.createElement('img');
    image.setAttribute('alt', content.title.rendered);
    image.setAttribute('src', content._embedded["wp:featuredmedia"][0].source_url);

    let box_content = document.createElement('div');
    box_content.classList.add('box__content');

    let title = document.createElement('h3');
    title.classList.add('box__title', 'title', 'title--size-24');
    title.setAttribute('title', content.title.rendered);
    title.innerHTML = content.nt_title;

    let excerpt = null;
    if (type !== 'large') {
        excerpt = document.createElement('div');
        excerpt.classList.add('box__excerpt');
        excerpt.innerHTML = content.nt_excerpt;
    }

    let data = document.createElement('div');
    data.classList.add('box__data');

    let publish_date = document.createElement('div');
    publish_date.classList.add('box__publish-date');
    publish_date.innerHTML = content.nt_publish_date;

    let read_time = document.createElement('div');
    read_time.classList.add('box__read-time');
    read_time.innerHTML = content.nt_read_time;

    image_container.appendChild(image);
    data.appendChild(publish_date);
    data.appendChild(read_time);

    if (type === 'large') {
        box_content.appendChild(category);
    }
    box_content.appendChild(title);

    if (excerpt !== null) {
        box_content.appendChild(excerpt);
    }

    box_content.appendChild(data);

    if (type === 'default') {
        box.appendChild(category);
    }
    box.appendChild(image_container);
    box.appendChild(box_content);
    return box;
}
export default createBlogBox;