import Splide from "@splidejs/splide";

class Slider {
    constructor(selector) {
        this.selector = selector;
        this.slides_container = this.selector.querySelector('[data-slides]');
        this.slides = this.slides_container.querySelectorAll('[data-index]');
        this.dots_container = this.selector.querySelector('[data-dots]');
        this.dots = this.dots_container.querySelectorAll('[data-index]');
        this.arrow_next = this.selector.querySelector('[data-arrow-next]');
        this.arrow_prev = this.selector.querySelector('[data-arrow-prev]');
        this.currentSlide = 0;
        this.max_slides = this.slides.length;
        this.interval = null;

        if (window.innerWidth > 767) {
            this._startSlider();

            if (this.arrow_prev !== null) {
                this._prevSlideEvent = this._prevSlideEvent.bind(this);
                this.arrow_prev.addEventListener('click', this._prevSlideEvent);
            }

            if (this.arrow_next !== null) {
                this._nextSlideEvent = this._nextSlideEvent.bind(this);
                this.arrow_next.addEventListener('click', this._nextSlideEvent);
            }

            this._stopSlider = this._stopSlider.bind(this);
            this.selector.addEventListener('mouseenter', this._stopSlider);

            this._startSlider = this._startSlider.bind(this);
            this.selector.addEventListener('mouseleave', this._startSlider);

            this.slides.forEach(slide => {
                this._goToSlide = this._goToSlide.bind(this);
                slide.addEventListener('click', this._goToSlide);
            });

            this.dots.forEach(dot => {
                this._goToDot = this._goToDot.bind(this);
                dot.addEventListener('click', this._goToDot);
            });
        } else {
            let slide = new Splide(this.selector, {
                perPage: 1,
                rewind: true,
                fixedWidth: 'calc(100% - 10px)',
                gap: 8,
                pauseOnHover: true
            });
            slide.mount();
        }
    }
    _clearActiveSlide() {
        this.slides.forEach(slide => {
            if (slide.classList.contains('slider__slide--active')) {
                slide.classList.remove('slider__slide--active');
            }
        });
    }
    _setActiveSlide(index) {
        this._clearActiveSlide();
        let slide = this.selector.querySelector('[data-index="' + index + '"]');
        if (slide !== null) {
            slide.classList.add('slider__slide--active');
            this._setActiveDot(index);
        }
    }
    _nextSlide() {
        if (this.currentSlide >= (this.max_slides-1)) {
            this.currentSlide = 0;
        } else {
            this.currentSlide++;
        }
        this._setActiveSlide(this.currentSlide);
        this._setActiveDot(this.currentSlide);
    }
    _nextSlideEvent(e) {
        e.preventDefault();
        this._clearInterval();
        this._nextSlide();
        this._startSlider();
    }
    _prevSlide() {
        if (this.currentSlide === 0) {
            this.currentSlide = (this.max_slides-1);
        } else {
            this.currentSlide--;
        }
        this._setActiveSlide(this.currentSlide);
        this._setActiveDot(this.currentSlide);
    }
    _prevSlideEvent(e) {
        e.preventDefault();
        this._clearInterval();
        this._prevSlide();
        this._startSlider();
    }
    _clearInterval() {
        clearInterval(this.interval);
        this.interval = null;
    }
    _stopSlider() {
        this._clearInterval();
    }
    _startSlider() {
        if (this.interval == null) {
            this.interval = setInterval(() => {
                this._nextSlide();
            }, 5000);
        }
    }
    _goToSlide(e) {
        let index = e.target.getAttribute('data-index');
        if (index == null) {
            index = e.target.closest('[data-index]').getAttribute('data-index');
        }
        if (this.currentSlide !== index) {
            this._clearInterval();
            this._setActiveSlide(index);
            this._setActiveDot(index);
            this.currentSlide = index;
            this._startSlider();
        }
    }
    _clearActiveDot() {
        this.dots.forEach(slide => {
            if (slide.classList.contains('slider__dot--active')) {
                slide.classList.remove('slider__dot--active');
            }
        });
    }
    _setActiveDot(index) {
        this._clearActiveDot();
        let dot = this.dots_container.querySelector('[data-index="' + index + '"]');
        if (dot !== null) {
            dot.classList.add('slider__dot--active');
        }
    }
    _goToDot(e) {
        e.preventDefault();

        let index = e.target.getAttribute('data-index');
        if (index !== null && this.currentSlide !== index) {
            this._clearInterval();
            this._setActiveSlide(index);
            this._setActiveDot(index);
            this.currentSlide = index;
            this._startSlider();
        }
    }
}
export default Slider;