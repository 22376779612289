class BlogList {
    constructor(selector) {
        this.selector = selector;
        this.cats = this.selector.querySelector('[data-categories]');
        this.postContainer = this.selector.querySelector('[data-container]');
        this.loader = this.selector.querySelector('[data-loader]');
        this.button = this.selector.querySelector('[data-button]');
        this.current_cat_id = -1;

        this.isLoading = false;

        this._loadAttributes();

        if (this.loadOnInit) {
            this._loadPosts();
        }

        if (this.cats !== null) {
            let items = this.cats.querySelectorAll('[data-cat-id]');
            if (items !== null) {
                items.forEach(item => {
                    this._setCurrentCategory = this._setCurrentCategory.bind(this);
                    item.addEventListener('click', this._setCurrentCategory);
                });
            }
        }
    }

    _loadAttributes(){
        let types = this.selector.getAttribute('data-type');
        if(types !== null && types !== '') {
            this.type = types;
        } else this.type = 'posts';

        let perPage = this.selector.getAttribute('data-per-page');
        if(perPage !== null && perPage !== ''){
            this.perPage = parseInt(perPage);
        } else this.perPage = 10;

        let loadOnInit = this.selector.getAttribute('data-load-on-init');
        if(loadOnInit !== null && loadOnInit !== ''){
            this.loadOnInit = (loadOnInit === 'true');
        } else this.loadOnInit = false;
    }

    _setCurrentCategory(e) {
        e.preventDefault();
        let cat_id = parseInt(e.target.getAttribute('data-cat-id'));
        let permalink = e.target.getAttribute('data-permalink');

        this._clearAllTabs();
        e.target.parentNode.classList.add('blog-categories__item--active');
        this.current_cat_id = cat_id;
        this._loadPosts();

        this._setButtonPermalink(permalink);
    }

    _clearAllTabs() {
        if (this.cats !== null) {
            let items = this.cats.querySelectorAll('[data-cat-id]');
            if (items !== null) {
                items.forEach(item => {
                    item.parentNode.classList.remove('blog-categories__item--active');
                });
            }
        }
    }

    _getFromAPI(){
        let queryString = this._buildQuery();
        let url = '/wp-json/wp/v2/' + this.type + '?'+queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _buildQuery(){
        let query = [
            'per_page=' + this.perPage
        ];

        if (this.current_cat_id !== -1) {
            query.push('categories=' + this.current_cat_id);
        }
        query.push('_embed');

        return query.join('&');
    }

    _loadPosts() {
        this.postContainer.innerHTML = '';
        this._showLoaderAnimation();
        this.isLoading = true;
        this._getFromAPI().then(response => {
            if(response.length > 0){
                this._generatePosts(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }
    _generatePosts(posts){
        posts.forEach((post, key) => {
            let type = 'default';
            if (posts.length > 3 && (key === 1 || key === 5)) {
                type = 'large';
            }
            let postElement = window.createBlogBox(post, type);
            this.postContainer.append(postElement);
        });
        this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _showLoaderAnimation() {
        this.loader.classList.add('blog-posts__loader--shown');
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove('blog-posts__loader--shown');
    }
    _noResults(){
        let text = document.createElement('div');
        text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Ta kategoria nie posiada żadnych wpisów';
        this.postContainer.appendChild(text);
        this._hideLoaderAnimation();
    }
    _handleError(error){
        let text = document.createElement('div');
        text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = 'Ta kategoria nie posiada żadnych wpisów';
        this.postContainer.appendChild(text);
        this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _setButtonPermalink(new_permalink) {
        this.button.setAttribute('href', new_permalink)
    }
}
export default BlogList;