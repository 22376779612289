class SortSelect {
    constructor(selector) {
        this.selector = selector;
        this.selector.addEventListener('change', (e) => {
            let value = e.target.value,
                address = window.location.href;

            if (address.includes('?filter')) {
                window.location = address + '&sort=' + value;
            } else if (address.includes('?sort')) {
                let n_address = address.split('=')[0];
                window.location = n_address + '=' + value;
            } else window.location = address + '?sort=' + value;
        });
    }
}
export default SortSelect;