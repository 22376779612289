import Splide from "@splidejs/splide";

class PopularArticles {
    constructor(selector) {
        this.selector = selector;
        this.container = this.selector.querySelector('[data-container]');
        this.loader = this.selector.querySelector('[data-loader]');

        this._loadPosts();
    }
    _getFromAPI() {
        let url = '/wp-json/wp/v2/posts?per_page=10&_embed&is_popular';
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _loadPosts(){
        this._showLoaderAnimation();
        this._getFromAPI().then(response => {
            if(response.length > 0){
                this._generatePosts(response);
            } else {
                this._noResults();
            }
        }).catch(error => {
            this._handleError(error);
        })
    }
    _generatePosts(posts) {
        posts.forEach((post) => {
            let postElement = window.createBlogBox(post, 'default');
            let carousel_container = document.createElement('li');
            carousel_container.classList.add('splide__slide');
            carousel_container.appendChild(postElement);
            this.container.append(carousel_container);
        });
        this._carouselInit();
        this._hideLoaderAnimation();
    }
    _showLoaderAnimation() {
        this.loader.classList.add('popular-articles__loader--shown');
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove('popular-articles__loader--shown');
    }
    _noResults(){
        let text = document.createElement('div');
        text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = '1';
        this.container.appendChild(text);
        this._hideLoaderAnimation();
    }
    _handleError(error){
        let text = document.createElement('div');
        text.classList.add('blog-posts__posts-not-found', 'title', 'title--size-24', 'title--center');
        text.innerHTML = '2';
        this.container.appendChild(text);
        this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _carouselInit() {
        let oa_carousel = new Splide(this.selector, {
            perPage: 3,
            rewind: true,
            gap: 24,
            breakpoints: {
                1024: {
                    perPage: 3,

                },
                850: {
                    perPage: 2,

                },
                550: {
                    perPage: 1,
                    fixedWidth: 'calc(100% - 30px)',
                    gap: 8,
                },
            },
        });
        oa_carousel.mount();
    }
}
export default PopularArticles;