import Splide from "@splidejs/splide";
import createBlogBox from "./createBlogBox";
import BlogList from "./blogList";
import SortSelect from "./sortSelect";
import PopularArticles from "./popularArticles";
import Choices from "choices.js";
import {Form} from "@netivo/base-scripts";
import Slider from "./slider";

window.createBlogBox = createBlogBox;

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop;
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) + 100;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);

    let popularArticles = document.querySelector('.js-popular-articles');
    if (popularArticles !== null) {
        new PopularArticles(popularArticles);
    }

    let otherArticles = document.querySelector('.js-other-articles');
    if (otherArticles !== null) {
        let oa_carousel = new Splide(otherArticles, {
            perPage: 3,
            rewind: true,
            gap: 24,
            breakpoints: {
                1024: {
                    perPage: 3,

                },
                850: {
                    perPage: 2,

                },
                550: {
                    perPage: 1,
                    fixedWidth: 'calc(100% - 30px)',
                    gap: 8
                },
            }
        });
        oa_carousel.mount();
    }

    let blog_list = document.querySelector('.js-blog-posts');
    if (blog_list !== null) {
        new BlogList(blog_list);
    }

    let sort_select = document.querySelector('.js-sort-select');
    if (sort_select !== null) {
        new SortSelect(sort_select);
    }

    let choicesfields = document.querySelectorAll('.js-choices');
    if(choicesfields !== null){
        choicesfields.forEach(element => {
            new Choices(element, {
                itemSelectText: 'Wybierz temat',
                searchEnabled: false,
            });
        })
    }

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        let recaptcha_key = contact_form.getAttribute('data-recaptcha');
        let close_button = contact_form.querySelector('[data-button-close]');
        new Form(contact_form, {
            action: '/wp-json/dp/v1/contact_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        subject: '',
                        name: '',
                        contact: '',
                        message: '',
                        recaptcha: '',
                    };

                    let form_name = el.querySelector('[name="name"]'),
                        form_subject = el.querySelector('[name="subject"]'),
                        form_contact = el.querySelector('[name="contact"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_name !== null) data.name = form_name.value;
                    if (form_subject !== null) data.subject = form_subject.value;
                    if (form_contact !== null) data.contact = form_contact.value;
                    if (form_message !== null) data.message = form_message.value;

                    grecaptcha.ready(function() {
                        grecaptcha.execute(recaptcha_key, {action: 'contact'}).then(function (token) {
                            data.recaptcha = token;
                            resolve(data);
                        });
                    });
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    status.style.display = 'flex';
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="name"]').value = '';
                        el.querySelector('[name="subject"]').value = '';
                        el.querySelector('[name="contact"]').value = '';
                        el.querySelector('[name="message"]').value = '';

                        if (close_button !== null) {
                            close_button.addEventListener('click', e => {
                                e.preventDefault();

                                message.classList.remove('shown');
                                status.classList.remove('shown');
                                status.style.display = 'none';
                            });
                        }
                    }, 500);
                }
            },
            error: (el, response) => {
                console.log(response);
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');

                status.style.display = 'flex';
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown');

                setTimeout( () => {
                    message.classList.add('shown');
                    setTimeout(() => {
                        message.classList.remove('shown');
                        status.classList.remove('shown');
                        status.style.display = 'none';
                    }, 5000);
                }, 500);
            }
        });
    }

    let slider = document.querySelector('.js-slider');
    if (slider !== null) {
        new Slider(slider);
    }
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 200;
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});